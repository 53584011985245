import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import AuthContext from "./AuthContext";
import AddressContext from "./AddressContext";
// import { WalletContext } from "./WalletContext";
import moment from "moment";
import GLobalStoreContext from "./GlobalStoreContext";
import HomePageContext from "./HomepageContext";
import CompRenderingContext from "./CompRenderingContext";
import { serviceCheckForCart } from "../components/checkout/subcomponents/serviceCheckForCart";
import { useLocation } from "react-router-dom";

const CartContext = createContext();
export const useCartContext = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const isBrowser = typeof window !== "undefined";
  const { pathname } = useLocation();
  // ----------- ** LOCAL STORAGE ** ----------------
  const localCart = isBrowser
    ? JSON.parse(localStorage?.getItem("cart"))
    : null;
  const LC_TEMP_CART = isBrowser
    ? JSON.parse(localStorage?.getItem("LC_TEMP_CART"))
    : null;
  const LC_CART_CHANGE = isBrowser
    ? JSON.parse(localStorage?.getItem("LC_CART_CHANGE"))
    : null;
  const whatsappNumber = "+919930699955";

  // ----------- ** CONTEXT ** ----------------
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  // const { totalEboCoins } = useContext(WalletContext);
  const { getDecorOccassionCategoryName } = useContext(HomePageContext);

  const { selectedAddress } = useContext(AddressContext);
  const {
    locationData,
    setLocationData,
    setIsAddressSelector,
    setUniversalModalsRenderInfo,
  } = useContext(CompRenderingContext);

  const { orderInfo, setOrderInfo } = useContext(GLobalStoreContext);

  const { user } = useContext(AuthContext);

  // ---------------- ** STATES ** ---------------
  const [cartState, setCartState] = useState({
    cart: localCart || {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    type: "",
  });

  const [tempCart, setTempCart] = useState(LC_TEMP_CART);

  // ---------------- ** USE EFFECTS ** ---------------

  useEffect(() => {
    if (user) {
      handleCartPresence();
    }
    // eslint-disable-next-line
  }, [user?.id]);

  useEffect(() => {
    handleOfferInvalidationCheck();
    // eslint-disable-next-line
  }, [LC_TEMP_CART]);

  useEffect(() => {
    handleAddressChangeValidation();
    // eslint-disable-next-line
  }, [locationData?.selectedLocation?.hubId]);

  // ---------------- ** FUNCTIONS ** ---------------

  const handleOfferInvalidationCheck = async () => {
    const { offer_min_value_invalidation_check } = await import(
      "../functions/tempCartFunc"
    );
    if (tempCart && !Object.keys(tempCart?.offers).length === 0) {
      const isInvalid = LC_TEMP_CART
        ? offer_min_value_invalidation_check(
            LC_TEMP_CART.offers[0],
            LC_TEMP_CART,
            locationData?.selectedLocation?.hubId
          )
        : false;
      if (isInvalid.invalid) {
        temp_cart_add_offer(null);
      }
    }
  };

  const handleAddressChangeValidation = async () => {
    if (!tempCart) return;

    const result = await serviceCheckForCart(
      locationData?.selectedLocation?.hubId,
      tempCart
    );

    if (result.success) {
      if (result.service) {
        if (result.cartChange) {
          setCartState((p) => ({ ...p, cart: result.cart }));
          setTempCart(result.cart);
          localStorage.setItem("LC_TEMP_CART", JSON.stringify(result.cart));
          localStorage.setItem("LC_CART_CHANGE", true);
        }
      } else {
        if (pathname === "/checkout") {
          // service is missing
          setUniversalModalsRenderInfo((p) => ({
            ...p,
            warningModel: {
              msg: "Oops! Not serviceable in your location. Try other locations or contact us for customized decors.",
              leftBtnText: "Chat with us",
              rightBtnText: "Change location",
              isRender: true,
              funOnLeftBtn: () => {
                window.openUrl(
                  `https://wa.me/${whatsappNumber}?text=Hi, I'm ${
                    user ? user.name : "new at ebo"
                  }, I'm trying to book a product whose product code is ${
                    tempCart.product._id
                  }.`
                );
              },
              funOnRightBtn: () => {
                setIsAddressSelector(true);
              },
            },
          }));
        }
      }
    }
  };

  // -------> handle the cart presence after user login or after the user's page reload
  const handleCartPresence = async () => {
    let validUserCart = LC_TEMP_CART;

    if (!LC_TEMP_CART) {
      const res_cart = await getCartForUser();
      if (res_cart) {
        if (res_cart.product === null) {
          await deleteCart();
          return;
        }
        setCartState({
          ...cartState,
          cart: { res_cart },
        });
        const { existing_cart_to_temp_cart } = await import(
          "../functions/tempCartFunc"
        );
        const newTempCart = await existing_cart_to_temp_cart(res_cart);
        window && localStorage.setItem("cart", JSON.stringify(res_cart));
        window &&
          localStorage.setItem("LC_TEMP_CART", JSON.stringify(newTempCart));
        setTempCart(newTempCart);
        validUserCart = newTempCart;
      }
    } else if (LC_CART_CHANGE) {
      if (LC_TEMP_CART.product === null) {
        await deleteCart();
        return;
      }
      temp_cart_to_cart(LC_TEMP_CART);
    }
    // -------> checking the date is valid in the cart or not
    if (
      LC_TEMP_CART &&
      LC_TEMP_CART?.bookingDate !== "" &&
      LC_TEMP_CART?.bookingDate !== null
    ) {
      const validDate = moment().add(
        LC_TEMP_CART?.product?.minDayOfPreparation
          ? LC_TEMP_CART.product.minDayOfPreparation
          : 0,
        "days"
      );

      if (moment(LC_TEMP_CART.bookingDate, "DD-MM-YYYY").isBefore(validDate)) {
        await temp_cart_booking_details({
          bookingDate: validDate.format("DD-MM-YYYY"),
          bookingTime: "",
        });
      } else {
        setOrderInfo({
          selectedDate: LC_TEMP_CART.bookingDate,
          selectedTimeSlot: LC_TEMP_CART.bookingTime,
        });
      }
    }
    return validUserCart;
  };

  const offerModalRef = useRef(null);

  // Temp cart functions

  // temp_cart_to_cart *** update / upload temp cart to database api calling ***
  const temp_cart_to_cart = useCallback(
    async (LC_TEMP_CART) => {
      const { temp_cart_structuring } = await import(
        "../functions/tempCartFunc"
      );
      const structuredCart = await temp_cart_structuring(LC_TEMP_CART);
      try {
        const res = await axios.post(
          `${REACT_APP_BACKEND_URL}/api/users/cart/temp_cart_to_cart`,
          { structuredCart },
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        setCartState({
          ...cartState,
          cart: res.data,
        });
        window && localStorage.setItem("cart", JSON.stringify(res.data));
        window && localStorage.removeItem("LC_CART_CHANGE");
      } catch (error) {
        console.error(error);
      }
    },
    [REACT_APP_BACKEND_URL, user, cartState]
  );

  /**
   *
   * In order to update the booking Details
   */
  const temp_cart_booking_details = async ({
    santaTip,
    bookingAddress,
    bookingDate,
    bookingTime,
    readyTime,
    arrivingTime,
    celebrationDate,
  }) => {
    const { constructing_temp_cart } = await import(
      "../functions/tempCartFunc"
    );
    const newTempCart = await constructing_temp_cart({
      tempCart,
      santaTip,
      bookingAddress,
      bookingDate,
      bookingTime,
      hubId: locationData.selectedLocation?.hubId,
      readyTime,
      arrivingTime,
      celebrationDate,
    });
    setTempCart(newTempCart);
    temp_cart_to_cart(newTempCart);
  };

  //  in order to add, modify,remove addon from temp cart
  const temp_cart_add_addon = async ({
    addon,
    text,
    color,
    quantity,
    LC_TEMP_CART,
  }) => {
    // checking is the addon is already there in cart or not
    let addOns = LC_TEMP_CART?.addOns || tempCart.addOns;
    const structred_addon = {
      addon: addon,
      quantity: quantity,
      text: text,
      color: color,
    };

    if (addon.isText && text === "") {
      addOns = addOns.filter((curr) => {
        return curr.addon._id !== addon._id;
      });
    } else if (text === "" && color === "" && quantity === 0) {
      addOns = addOns.filter((curr) => {
        return curr.addon._id !== addon._id;
      });
    } else if (addOns.length === 0) {
      addOns = [structred_addon];
    } else {
      const matchingAddon = addOns.filter((curr) => {
        return curr.addon._id === addon._id;
      });
      if (matchingAddon && matchingAddon.length === 0) {
        addOns.push(structred_addon);
      } else {
        let removeFlag = false;
        addOns = addOns.map((curr) => {
          if (curr.addon._id === addon._id) {
            if (
              curr.quantity + quantity === 0 &&
              curr.text === "" &&
              curr.color === ""
            ) {
              removeFlag = true;
            }
            return {
              addon: addon,
              text: text,
              color: color,
              quantity: addon.isText ? quantity : curr.quantity + quantity,
            };
          }
          return curr;
        });
        if (removeFlag) {
          addOns = addOns.filter((curr) => {
            return curr.addon._id !== addon._id;
          });
        }
      }
    }
    const { constructing_temp_cart } = await import(
      "../functions/tempCartFunc"
    );
    const newTempCart = await constructing_temp_cart({
      tempCart: LC_TEMP_CART || tempCart,
      addOns: addOns,
      hubId: locationData.selectedLocation?.hubId,
    });
    window && localStorage.setItem("LC_CART_CHANGE", true);
    setTempCart(newTempCart);

    return newTempCart;
  };
  //  in order to handel offer addition and removal
  const temp_cart_add_offer = async (
    offer,
    tC // temp cart
  ) => {
    let currTempCart = tC || tempCart;
    let offers = currTempCart?.offers;

    if (!offer) {
      offers = [];
    } else if (offers.length === 0) {
      offers.push({ offer: offer });
    } else {
      offers = [{ offer: offer }];
    }
    const { constructing_temp_cart } = await import(
      "../functions/tempCartFunc"
    );
    const newTempCart = await constructing_temp_cart({
      tempCart: currTempCart,
      offers,
      hubId: locationData.selectedLocation?.hubId,
    });
    // window && localStorage.setItem("LC_CART_CHANGE", true);
    setTempCart(newTempCart);
    temp_cart_to_cart(newTempCart);

    return newTempCart;
  };

  // in order to handle the celebrating for data changes
  const temp_cart_change_celebratingFor = async (celebratingFor) => {
    const { constructing_temp_cart } = await import(
      "../functions/tempCartFunc"
    );
    const newTempCart = await constructing_temp_cart({
      tempCart,
      celebratingFor,
      hubId: locationData.selectedLocation?.hubId,
    });
    setTempCart(newTempCart);
    window && localStorage.setItem("LC_CART_CHANGE", true);
    return true;
  };
  // in order to handle the celebrating for data changement
  const temp_cart_change_freebie = async (freebie) => {
    const { constructing_temp_cart } = await import(
      "../functions/tempCartFunc"
    );
    const newTempCart = await constructing_temp_cart({
      tempCart,
      hubId: locationData.selectedLocation?.hubId,
      freebie,
    });
    setTempCart(newTempCart);
    window && localStorage.setItem("LC_CART_CHANGE", true);
    return true;
  };

  // in order to handle the adding/updating instructions
  const temp_cart_change_instructions = async (instructions) => {
    const { constructing_temp_cart } = await import(
      "../functions/tempCartFunc"
    );
    const newTempCart = await constructing_temp_cart({
      tempCart,
      instructions,
      hubId: locationData.selectedLocation?.hubId,
    });
    window && localStorage.setItem("LC_CART_CHANGE", true);
    setTempCart(newTempCart);
    return true;
  };
  // to create the temp cart
  const tempCartCreate = async (product) => {
    if (!locationData?.selectedLocation?.hubId) {
      setUniversalModalsRenderInfo((p) => ({
        ...p,
        warningModel: {
          msg: "You need to select a location first.",
          leftBtnText: "Select later",
          rightBtnText: "Select now",
          isRender: true,
          funOnLeftBtn: () => {},
          funOnRightBtn: () => {
            setLocationData((p) => ({
              ...p,
              isLocationAddModalRender: true,
              isAddressSaveModal: false,
            }));
          },
        },
      }));

      return;
    }
    const { create_temp_cart } = await import("../functions/tempCartFunc");
    const LC_TEMP_CART = await create_temp_cart({
      hubId: locationData?.selectedLocation?.hubId,
      bookingAddress: selectedAddress,
      bookingTime: orderInfo.selectedTimeSlot,
      bookingDate: orderInfo.selectedDate,
      product: product,
      user: user,
      getDecorOccassionCategoryName,
    });
    setTempCart(LC_TEMP_CART);
    user
      ? temp_cart_to_cart(LC_TEMP_CART)
      : window && localStorage.setItem("LC_CART_CHANGE", true);
    return LC_TEMP_CART;
  };

  // temp cart updating to data base
  const getCartForUser = useCallback(async () => {
    try {
      const { get_cart_for_user } = await import("../api/cartApi");
      const res = await get_cart_for_user();
      if (res.isSuccess) return res.cart;
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }, []);

  // -------------------------

  const deleteCart = useCallback(async () => {
    try {
      if (user) {
        const { delete_cart } = await import("../api/cartApi");
        const res = await delete_cart();
        if (res.isSuccess) {
          setCartState({
            ...cartState,
            cart: {},
          });
          setTempCart(null);
          window && localStorage.removeItem("LC_TEMP_CART");
          window && localStorage.removeItem("LC_CART_CHANGE");
          window && localStorage.removeItem("cart");
        }
      } else {
        setTempCart(null);
        window && localStorage.removeItem("LC_TEMP_CART");
        window && localStorage.removeItem("LC_CART_CHANGE");
        window && localStorage.removeItem("cart");
      }
    } catch (error) {
      console.error(error);
    }
  }, [cartState, user]);

  // function to calculate coins Discount based on the final buying price
  const calculate_coins_discount = (buyingPrice) => {
    const max_coins_to_be_discounted =
      Math.round(buyingPrice / 20) < 200 ? Math.round(buyingPrice / 20) : 200;

    return 0;
    // return max_coins_to_be_discounted < totalEboCoins ? max_coins_to_be_discounted : totalEboCoins;
  };

  const [isUsingCoin, setIsUsingCoin] = useState(false); // this state
  const [amountToPay, setAmountToPay] = useState(tempCart?.buyingPrice);
  const [coinsDiscount, setCoinsDiscount] = useState(0);
  const [coinsDiscountToShow, setCoinsDiscountToShow] = useState(
    calculate_coins_discount(tempCart?.buyingPrice)
  );

  // handler function to update the isUsingCoin state
  const handleIsUsingCoinState = (props) => {
    if (props) {
      props === "No" && setIsUsingCoin(false);
      props === "Yes" && setIsUsingCoin(true);
    } else setIsUsingCoin((prev) => props || !prev);
  };

  useEffect(() => {
    // const _coinsDiscount = calculate_coins_discount(tempCart?.buyingPrice);
    // setCoinsDiscountToShow((prev) =>
    //   calculate_coins_discount(tempCart?.buyingPrice || 0)
    // );
    // if (isUsingCoin) {
    //   setCoinsDiscount(calculate_coins_discount(tempCart?.buyingPrice));
    //   setAmountToPay(tempCart?.buyingPrice - _coinsDiscount);
    // } else {
    //   setAmountToPay(tempCart?.buyingPrice);
    //   setCoinsDiscount(0);
    // }
    setAmountToPay(tempCart?.buyingPrice);
  }, [tempCart, isUsingCoin]);

  return (
    <CartContext.Provider
      value={{
        cartState,
        setCartState,
        deleteCart,
        offerModalRef,
        tempCartCreate,
        tempCart,
        setTempCart,
        temp_cart_add_addon,
        temp_cart_add_offer,
        temp_cart_change_celebratingFor,
        temp_cart_change_instructions,
        temp_cart_booking_details,
        temp_cart_to_cart,
        LC_TEMP_CART,
        coinsDiscount,
        coinsDiscountToShow,
        amountToPay,
        isUsingCoin,
        handleIsUsingCoinState,
        temp_cart_change_freebie,
        handleCartPresence,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
